body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f4f6f8; */
  /* min-height: 100vh; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  /* --bkg-color: rgba(15, 23, 33, .9); */
  --svg1: #F1F1EF;
  --svg2: #F1F1EF;
  --svg3: #F1F1EF;
  --svg4: #F1F1EF;
  --svg5: #0F1721;
  --svg6: rgba(15, 23, 33, 0.98);
  --headerColor: #6314FF;
  --tableBorder: #D1D1DE;
}

/* Light theme colors */
body.theme-light {
  --svg1: #121212;
  --svg2: #121212;
  --svg3: #121212;
  --svg4: #121212;
  --svg5: #121212;
  --svg6: rgba(15, 23, 33, 0.98);
  --headerColor: #6314FF;
  --tableBorder: #0f172162;
}



/* Dark theme colors */

body.dark {
  /* --bkg-color: rgba(15, 23, 33, .9); */
}

/* Styles for users who prefer dark mode at the OS level */
@media (prefers-color-scheme: dark) {
  /* body {
    --bkg-color: var(--bs-dark-rgb);
  } */

  /* Dark theme colors */

  /* body.light {
    --bkg-color: 236, 236, 236;
  } */
}

body {
  /* background: rgba(15, 23, 33, 1) !important; */

}

/*
Fix annotation layer rendering for react-pdf
*/
/* a:visited */
.linkAnnotation {
  position: absolute;
}

.linkAnnotation a {
  display: flex;
  width: 100%;
  height: 100%;
}

.MuiIconButton-root[data-description="row-select-header"] {
  top: 5px;
}

/* input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px rgba(15, 23, 33, .9) inset !important;
} */

.theme-light input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px rgb(223 223 223 / 90%) inset !important;
}

.theme-light .MuiOutlinedInput-input:-webkit-autofill {
  -webkit-text-fill-color: #343333;
}



:not(::-webkit-scrollbar) {
  scrollbar-width: thin;
  scrollbar-color: white transparent;
}

::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #D1D1DE !important;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #D1D1DE !important;
  border: 0.5px solid #D1D1DE !important;
}

::-webkit-scrollbar-corner {
  background: transparent !important;
}

.MuiListItem-root.MuiListItem-button:has(span.null-value) {
  display: none;
}